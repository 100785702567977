@import "../../assets/styles/global";
@import "../../assets/styles/font_size_mixin";
@import "../../assets/styles/blobz/blobz.min.css";
@import "../../assets/styles/page-layout";

#root {
  z-index: -1;
}

.hero {
  margin-top: -8.3em;
}
.master-container {
  display: flex;
  position: relative;
  z-index: -1;
  .background {
    z-index: -20;
    position: absolute;
    justify-self: center;
    align-self: center;
    background: radial-gradient(ellipse at top, $medium, transparent),
      radial-gradient(ellipse at bottom, $light, transparent);
    width: 100%;
    height: 40%;
    @media only screen and (max-width: 1350px) {
      height: 25%;
    }
    @media only screen and (max-width: 1500px) {
      height: 25%;
    }
    @media only screen and (max-width: 860px) {
      height: 20%;
    }
    @media only screen and (max-width: 721px) {
      height: 12%;
    }
    @media only screen and (max-width: 650px) {
      height: 18%;
    }
    @media only screen and (max-width: 510px) {
      height: 15%;
    }
    @media only screen and (max-width: 410px) {
      height: 12%;
    }
  }

  .hero-statement-container {
    width: 100%;
    position: relative;
    display: flex;
    padding-top: 2em;
    justify-content: center;
    .changing-text {
      filter: drop-shadow(0px 5px 2px $medium);
      margin-top: 20%;
      margin-left: 5%;
      position: absolute;
      font-size: 4vw;
      color: $light;
      text-shadow: 2px 2px 5px $medium;
      @media only screen and (max-width: 650px) {
        margin-top: 45%;
        margin-left: 10%;
        font-size: 8vw;
      }
      @media only screen and (min-width: 1550px) {
        margin-top: 20%;
      }
    }

    .owen-container {
      position: absolute;
      display: flex;
      justify-content: center;
      max-width: 80vw;
      width: 100%;
      height: 100vh;
      z-index: -1;
      @media only screen and (min-width: 1550px) {
        margin-top: 0.5%;
      }
      @media only screen and (max-width: 650px) {
        margin-top: 10%;
      }

      .__hero {
        position: absolute;
        width: 50%;
        border-radius: 40px;
        @media only screen and (max-width: 650px) {
          width: 90%;
        }
      }
    }
  }

  .waves-container {
    position: absolute;
    width: 100%;
    z-index: -1;
    margin-top: 41%;
    @media only screen and (max-width: 650px) {
      margin-top: 80%;
    }
    .thin {
      position: absolute;
      z-index: 2;
      width: 100%;
      margin-top: -28%;
      filter: blur(10px);
    }
    .__waves {
      z-index: 1;
      height: auto;
      width: 100%;
    }
  }
  .bg {
    margin-top: 30%;
    z-index: -2;
    @media only screen and (max-width: 650px) {
      margin-top: 70%;
    }
  }

  .my-works {
    display: flex;
    flex-direction: column;
    margin-top: 65%;
    @media only screen and (max-width: 650px) {
      margin-top: 100%;
    }
    @media only screen and (min-width: 1350px) {
      margin-inline: 15%;
    }
    .working-on-text {
      margin: 1em 0em;
      justify-self: center;
      align-self: center;
    }
    .work-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: auto;
    }
  }
}
.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  // background-color: transparent;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
}
