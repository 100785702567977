@import "../../assets/styles/global";
@import "../../assets/styles/page-layout";

.prompt-text-container {
  display: flex;
  flex-direction: row;
  margin-inline: 15%;
  gap:2em;
  margin-bottom:2em;
  background-color:$light;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1600px) {
    flex-direction: column;
  }
}

.vid-container {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.support-summary {
  flex: 1;
  color: $gray;
  padding:2em;
  @media only screen and (max-width: 1600px) {
    padding-bottom:0em;
  }
}

.summary-title{
  background-color: $medium;
  color:$light;
  padding:1em;
  border-radius:8px;
  margin-bottom:1em;
}
.vid-container > video {
  border-radius: 8px;
}
.link{
  text-decoration: underline;
}

.problems-to-solve {
  padding: 0;
  margin-top: 1em;
  counter-reset: index;
  li {
    padding: 0.3em;
    counter-increment: index;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    
    &:before {
      content: counters(index, ".", decimal-leading-zero);
      font-size: 1.5rem;
      text-align: right;
      font-weight: bold;
      min-width: 50px;
    padding-right: 12px;
    font-variant-numeric: tabular-nums;
    align-self: flex-start;
    background-image: linear-gradient(to bottom, $light, $medium);
    background-attachment: fixed;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
li + li {
  border-top: 1px solid rgba(72, 72, 72, 0.3);
}
}
.bold{
  font-weight: bold;
}
.prompt-build-section{
  padding:1em 0em;
  border-bottom:1px solid #33333325;
  
}
.prompt-build-img{
  margin-top:1em;
}