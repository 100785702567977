@import "../../assets/styles/global";
@import "../../assets/styles/font_size_mixin";
@import "../../assets/styles/page-layout";
.WorkCard {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1em;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 20em;
  height: 30em;

  * {
    z-index: 2;
  }

  .card-header img {
    position: relative;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body {
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1em;
    p {
      @include responsive-font(2vw, 10px, 12px);
      margin: 5% 0;
    }

    .tag {
      background: $light;
      border-radius: 50px;
      font-size: 12px;
      margin-bottom: 5%;
      color: #fff;
      padding: 2px 10px;
      text-transform: uppercase;
      cursor: pointer;
    }
    .tag-teal {
      background-color: $medium;
    }
    .user-info h5 {
      margin: 0;
      color: $medium;
    }
    .user-info small {
      color: $medium;
    }

    .go-corner {
      background-color: $medium;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 32px 0px 4px 0px;
      position: absolute;
      bottom: 0;
      right: 0;
      overflow: hidden;

      .go-arrow {
        font-size: 16px;
        margin-bottom: -4px;
        margin-right: -4px;
        color: $light;
      }
    }
  }
  .card-body:before {
    content: "";
    z-index: 0;
    position: absolute;
    bottom: -16px;
    right: -16px;
    background: $medium;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.5s ease-out;
  }
  .card-body:hover:before {
    transform: scale(28);
  }
}

.card-body:hover {
  p {
    transition: all 0.4s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }
  h3,
  h4,
  .user-info h5,
  .user-info small {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
  .tag-teal {
    background-color: $light;
    color: $dark;
  }
}
