@import "./colors";
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");
@import "./font_size_mixin";
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300&display=swap");

@font-face {
  src: url(../fonts/AmasisMTW1G.otf);
  font-family: amasis;
}
// CSS RESETS~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html {
  font-size: 16px;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll; /* Add the ability to scroll */
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  //disable text highlighting
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}
#root {
  position: absolute;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 10px;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1.5;
}

ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: #f1f1f1f1;
  overflow-x: hidden;
  font-family: "Merriweather Sans", sans-serif;
}
h1 {
  @include responsive-font(3.5vw, 40px, 48px);
  font-family: "Fjalla One", sans-serif;
}
h2 {
  @include responsive-font(3vw, 24px, 32px);
  font-family: "Fjalla One", sans-serif;
}
h3 {
  @include responsive-font(2.5vw, 16px, 24px);
  font-family: "Fjalla One", sans-serif;
}
h4 {
  @include responsive-font(2vw, 16px, 24px);
  font-family: "Fjalla One", sans-serif;
}
p {
  @include responsive-font(2vw, 12px, 16px);
}
ul {
  @include responsive-font(2vw, 12px, 16px);
}
a:link {
  text-decoration: none;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Merriweather Sans", sans-serif;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Merriweather Sans", sans-serif;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Merriweather Sans", sans-serif;
}
