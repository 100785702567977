@import "./global";

.loadin {
    opacity: 0;
    transform: translateY(45px);
    transition: transform 0.6s, opacity 0.9s;

    &.loaded {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0s;
    }
}
.left {
    transform: translateY(-45px);
}
.master-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    
    .subtitle {
        color: $medium;
    }

    .title-container {
        width: 100%;
        box-sizing: border-box;
        padding-inline: 15%;
        margin-bottom: 3em;
        .title,
        .subtitle {
            padding-bottom: 0.5em;
        }
    }
    .text-container {
        box-sizing: border-box;
        margin-inline: 25%;
        @media only screen and (max-width: 600px) {
            margin-inline: 10%;
        }
        @media only screen and (min-width: 1200px) {
            margin-inline: 30%;
        }
        margin-bottom: 2.5em;
    }
    .image-tag {
        color: $gray;
    }
}
