@import "../../assets/styles/global";
@import "../../assets/styles/page-layout.scss";
.kanban-image {
  align-self: center;
  justify-self: center;
  width: 60%;
  border-radius: 20px;
  // margin-bottom: 5em;
  box-shadow: 1px 1px 10px $gray;
  @media only screen and (max-width: 900px) {
    width: 80%;
  }
}
.dark {
  color: rgb(174, 174, 174);
}
.header-dark {
  padding-bottom: 1em;
  color: $light;
}
.color-section {
  justify-self: center;
  align-self: center;
  padding-top: 2em;
  padding-bottom: 4em;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  flex-direction: inherit;
}
.gray {
  border-radius: 4em 4em 4em / 1em 1em 1em;
  padding: 2em 0em;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  flex-direction: inherit;
  background-color: $gray;
}
.extra-padding {
  padding-top: 2em;
}
.gray-top {
  @extend .color-section;
  border-radius: 4em 4em 4em / 1em 1em 1em;
  width: 100vw;
  background-color: $gray;
  z-index: -1;
  margin-bottom: 2em;
}
.carousel-root {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}
.raw-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.raw-data-frame {
  display: flex;
  align-items: flex-start;
  width: fit-content;
  height: 20em;
  overflow: scroll;
  box-shadow: 1px 1px 5px $gray;
  margin: 1em 0em;
  z-index: 999;
  border-radius: 10px;

  .raw-data {
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
  }
}
.car-container {
  width: 65%;
  border-radius: 5px;
  align-self: center;
  box-shadow: 1px 1px 10px $gray;
  display: flex;
  justify-content: center;
  align-items: center;

  .car-img {
    border-radius: 5px;
  }
  ul.thumbs {
    display: flex;
    justify-content: center;
  }
}
