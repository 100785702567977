@import "../assets/styles/global";

@keyframes growDown {
  0% {
    transform: scaleY(0);
    border-radius: 20px;
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 2em;
  z-index: 20;

  nav {
    width: 80%;
  }
  .logo__container {
    position: fixed;
    display: flex;
    right: 0;
    margin-right: 2em;
    cursor: pointer;
    z-index: 21;
    opacity: 1;
    .logo {
      width: 6em;
      margin-right: 1em;
      @media only screen and (max-width: 650px) {
        width: 5em;
      }
      @media only screen and (min-width: 1600px) {
        width: 8em;
      }
    }
  }

  //non mobile nav bar
  .nav__links {
    display: flex;
    align-items: center;
    list-style: none;

    li {
      display: inline-block;
      padding: 1em 1em;
      a {
        display: flex;
        align-items: center;
      }

      .nav-item {
        color: $dark;
        position: relative;
        padding: 0em 0.2em;
      }
      .nav-item:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 2px;
        background-color: $dark;
        transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
      }
      @media (hover: hover) and (pointer: fine) {
        .nav-item:hover::before {
          left: 0;
          right: auto;
          width: 100%;
        }
      }
      a:hover,
      li:active {
        text-decoration: none;
        color: yellow;
      }
    }
  }

  .dropdown-content {
    z-index: 20;
    position: absolute;
    left: 0;
    margin-top: 1em;
    margin-left: 1em;
    width: auto;
    text-align: left;
    display: none;
    padding: 1em;
    background: rgba(255, 255, 255, 0.35);
    box-shadow: 0px, 8px, 32px, 0, rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    border-radius: 20px;
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
  }

  .dropdown-item {
    padding: 0.5em;
    padding-left: 1em;
    margin: 0.5em;
    position: relative;
    transition: all 0.3s ease;
    display: block;
    h4 {
      color: black;
      font-size: 0.95rem;
    }
    &:hover {
      padding-left: 2em;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0px;
      width: 2px;
      background-color: $dark;
      transition: height 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover::before {
        bottom: 0;
        right: auto;
        height: 100%;
      }
    }
  }

  .dropbtn {
    // margin-bottom: 10px;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  //mobile nav bar
  .mobile-nav-toggle {
    visibility: hidden;
    position: absolute;
    width: 8%;
    margin-right: 2em;
    min-width: 2.5em;
    border-radius: 50px;
    cursor: pointer;
  }

  .__mobile-nav-toggle {
    width: 100%;
    height: auto;
    visibility: hidden;
  }

  .mobile-nav-exit {
    position: absolute;
    top: 0;
    left: 0;
    margin: 2em;
  }

  .mobile-nav {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: -100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.35);
    box-shadow: 0px, 8px, 32px, 0, rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    transform: translateX(-100vw);
    transition: 500ms;

    .mobile-nav-work-links {
      border-top: 2px solid $gray;
      border-bottom: 2px solid $gray;
      padding: 1em;
      margin: 1em 3em;
    }
    .__exit {
      width: 100%;
      max-width: 3em;
      height: auto;
    }
    a {
      display: flex;
      justify-content: center;
      text-align: center;
      h3 {
        font-size: 1.5rem;
      }
      padding-bottom: 1em;
      color: $text;
      z-index: 21;
    }
  }
  .active {
    transform: translateX(100vw);
    transition: 500ms;
    z-index: 999;
    z-index: 400;
  }

  @media only screen and (max-width: 750px) {
    .nav__links {
      visibility: hidden;
    }
    .mobile-nav-toggle {
      visibility: visible;
    }
    .__mobile-nav-toggle {
      z-index: 21;
      visibility: visible;
    }
  }
}
