@import "../../assets/styles/global";
@import "../../assets/styles/page-layout";

@keyframes delayed-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tree-background {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient($light, $medium, 20%);
  width: 100vw;
  margin-bottom: 5em;

  .__trees {
    z-index: -1;
    width: 100vw;
    align-self: flex-start;
  }
  .upside-down {
    margin-bottom: 10%;
    transform: scaleY(-1);
  }
  .__duo-img {
    margin-top: 20%;
    position: absolute;
    z-index: 1;
    margin-left: 50%;
    width: 75%;
    @media only screen and (min-width: 1000px) {
      margin-left: 30%;
    }
  }
  .__steve_arrow {
    position: absolute;
    width: 8vw;
    height: auto;
    opacity: 0;
    margin-left: 75%;
    animation: delayed-fade-in 1s forwards 2s;
    @media only screen and (max-width: 1000px) {
      display: none;
    }
  }
  .__rectangle {
    position: absolute;
    width: 40%;
    height: 35vh;
    max-height: 60%;
    margin-right: 40%;
    margin-bottom: 5%;
    @media only screen and (min-width: 1000px) {
      width: 30%;
    }
  }
  .summary-text {
    font-size: 1.3vw;
    position: absolute;
    text-align: center;
    width: 30%;
    margin-top: 16%;
    margin-right: 38%;
    @media only screen and (max-width: 900px) {
      font-size: 1.7vw;
    }
    @media only screen and (max-width: 600px) {
      font-size: 1.8vw;
    }
    @media only screen and (max-width: 400px) {
      font-size: 2vw;
    }
    @media only screen and (min-width: 1000px) {
      font-size: 1.2vw;
      width: 20%;
    }
  }
}
.__framework {
  width: 25%;
  height: auto;
  align-self: center;
}
.fw-image-tag {
  align-self: center;
  padding: 5em;
  color: $gray;
}
.process-outline {
  margin: 3em 0em;
  display: flex;
  flex-direction: row;
  gap: 4em 2em;
  align-items: flex-start;
  flex-wrap: wrap;
  @media only screen and (max-width: 600px) {
    gap: 0;
    margin: 0;
  }
  * {
    width: 50%;
    @media only screen and (max-width: 600px) {
      width: 100%;
      margin: 0.5em 0em;
    }
  }
  h4 {
    color: $gray;
    width: 40%;
    @media only screen and (max-width: 600px) {
      text-decoration: underline;
      margin-top: 1em;
      width: 100%;
    }
  }
}
.__rookies {
  width: 60%;
  border-radius: 20px;
  align-self: center;
  justify-self: center;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.4);
  margin-bottom: 2em;
}
.disclaimer {
  h3 {
    padding-bottom: 1em;
  }
  text-align: center;
  align-self: center;
  width: 40%;
  margin-bottom: 1em;
  @media only screen and (max-width: 600px) {
    width: 60%;
  }
}
.extra-margin {
  margin-top: 2em;
}
