@import "../../assets/styles/global";
@import "../../assets/styles/page-layout";

.three-word-tax {
  @media only screen and (max-width: 800px) {
    font-size: 8vw;
  }
}
.zane-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 2em;
  @media only screen and (max-width: 800px) {
    margin-top: 0;
  }
  .__zane-img {
    border-radius: 20px;
    width: 70%;
    @media only screen and (max-width: 800px) {
      margin-bottom: 1em;
    }
  }
  .text-ellipse {
    position: absolute;
    width: 50%;
    height: auto;
    margin-bottom: 45%;
    margin-right: 30%;
    filter: drop-shadow(30px 15px 15px rgb(0, 0, 0 / 0.2));
    @media only screen and (max-width: 800px) {
      bottom: 0;
      margin-bottom: -13%;
      margin-right: 0;
      width: 70%;
    }
    @media only screen and (max-width: 550px) {
      width: 92%;
      margin-bottom: -23%;
    }
  }

  .summary-text {
    position: absolute;
    font-size: calc(min(1.4vw, 20px));
    margin-top: -25%;
    transform: rotate(2deg);
    text-align: left;
    color: $text;
    width: 40%;
    margin-right: 25%;
    @media only screen and (max-width: 800px) {
      display: none;
    }
  }
}
.summary-text-mobile {
  align-self: center;
  font-size: 1.8vw;
  text-align: center;
  color: $text;
  width: 50%;
  margin-inline: 20%;
  @media only screen and (min-width: 800px) {
    display: none;
  }
  @media only screen and (max-width: 550px) {
    font-size: 2.3vw;
    margin-top: 5%;
    margin-bottom: -5%;
  }
}

.guide-examples {
  display: flex;
  align-items: center;
  margin-top: 3em;

  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 10%;

  padding: 2em;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    margin-top: 0;
  }
  @media only screen and (min-width: 1200px) {
    margin-inline: 10%;
  }

  .guide-description {
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .problem-solution {
      font-size: calc(min(1.4vw, 26px));
      @media only screen and (max-width: 600px) {
        font-size: 2.3vw;
      }
    }
  }
  .__guide {
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.4);
    width: 100%;
    border-radius: 20px;
    height: auto;
  }
  .two {
    @media only screen and (max-width: 600px) {
      order: 1;
    }
  }
  .caption {
    text-align: center;
    color: gray;
  }
}

.conclusion-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
  margin: 5em 0em;
  margin-inline: 25%;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    text-align: left;
    margin: 2em 15%;
  }
  .how-it-went > p {
    min-width: 30vw;
    font-size: calc(min(1.4vw, 26px));
    @media only screen and (max-width: 600px) {
      font-size: 3vw;
      text-align: left;
    }
  }
  .__planter {
    height: 30vw;
    width: auto;
    border-radius: 20px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.4);
    @media only screen and (max-width: 600px) {
      height: 60vw;
    }
  }
}
