@import "../../assets/styles/global";
@import "../../assets/styles/page-layout.scss";

.google-play-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 2em;
  .play-icon {
    width: 2em;
    display: inline;
    transition: 0.3s ease;
  }
}
.top-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .scale-summary-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 50%;
    @media only screen and (min-width: 800px) {
      width: 35%;
    }
  }
}
.play-store-link {
  &:hover {
    .play-icon {
      width: 2.2em;
      transition: 0.3s ease;
    }
  }
  &:after {
    .play-icon {
      width: 2em;
      margin-bottom: -0.5em;

      transition: 0.3s ease;
    }
  }
}
.mockup {
  position: relative;
  align-self: center;
  width: 90%;
  @media only screen and (min-width: 800px) {
    margin-top: -5%;
    margin-bottom: -5%;
  }
}

.blur-body {
  filter: blur(5px);
}
