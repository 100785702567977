@import "../../assets/styles/colors";
@import "../../assets/styles/global";
.Blog {
  margin: 0;
  padding: 0;
  display: block;
  width: 100vw;
  .title-container {
    margin-inline: 20%;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .blog-description {
    font-size: 1.2em;
    color: $dark;
    margin-bottom: 2em;
  }
  .blog-cards {
    margin-inline: 20%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
