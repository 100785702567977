@import "../../assets/styles/global";
@import "../../assets/styles/font_size_mixin";

@keyframes outline-depth {
  0% {
    text-shadow: 0em 0em 0 $medium;
  }
  50% {
    text-shadow: 0.05em 0.05em 0 $medium;
  }
  0% {
    text-shadow: 0em 0em 0 $medium;
  }
}

@-webkit-keyframes slide {
  0% {
    margin-left: -20em;
  }
  50% {
    margin-left: 0;
  }
  60% {
    margin-left: 0;
  }
  100% {
    margin-left: 20em;
    opacity: 0;
  }
}

@keyframes slide {
  0% {
    opacity: 0;
    margin-left: -20em;
  }
  50% {
    opacity: 1;
    margin-left: 0;
  }
  60% {
    opacity: 1;

    margin-left: 0;
  }
  100% {
    margin-left: 20em;
    opacity: 0;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fadeout {
  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.master-container {
  .content-container {
    max-width: 1400px;
    @media only screen and (min-width: 1450px) {
      border: 8px solid $dark;
      border-radius: 5px;
      padding: 1em;
      box-shadow: 12px 0 15px -4px rgba(31, 73, 125, 0.8),
        -12px 0 8px -4px rgba(31, 73, 125, 0.8);
    }

    display: flex;
    flex-wrap: wrap;
    // height: 100vh;
    align-self: center;

    .content-header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 20px;
      // padding: 2%;
      background-color: $light;
      @media only screen and (max-width: 700px) {
        padding: 10%;
      }

      .title {
        position: relative;
        display: inline-block;
        color: rgb(255, 255, 255);
        font-size: calc(min(8vw, 90px));
        margin-right: 28%;
        letter-spacing: 0.3em;
        padding: 20px 0px;
        -webkit-text-stroke: 1px $medium;
        -webkit-text-fill-color: transparent;
        text-shadow: 0em 0em $medium;
        animation: outline-depth 2s infinite;
        transition-timing-function: cubic-bezier(0.3, 0.7, 0.5, 0.3);
      }
    }

    .content {
      width: 68%;
      background-color: $accent;
      display: flex;
      border-radius: 20px;
      justify-content: center;

      .border {
        background-color: lightgray;
        border: 1vw solid rgb(80, 86, 93);
        padding: 2em;
        margin: 2em;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        justify-content: center;

        .form-title {
          color: $text;
          font-size: calc(min(3vw, 40px));
        }
        .form-subtitle {
          margin: 2em 0;
          font-size: calc(min(1.7vw, 25px));
        }
        .contact-form {
          align-self: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 80%;
          // height: 85%;

          .form-input {
            resize: none;
            width: 100%;
            margin: 0.6em 0em;
            padding: 1em;
            border-radius: 10px;
            border: 0px;
            font-family: "Merriweather Sans", sans-serif;

            box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.2);
          }
          .send-button {
            cursor: pointer;
            width: 60%;
            border-radius: 10px;
            padding: 0.5em;
            border: 0px;
            margin-top: 1em;
            box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
            transition: 0.3s ease;
            background: url("../../assets/images/contact/send-icon.svg")
                no-repeat center,
              $light;
            &:hover {
              box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.4);
              transition: 0.3s ease;
            }
            &:after {
              box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
      @media only screen and (max-width: 750px) {
        width: 100vw;
        .border {
          margin: 2em;
          .form-title {
            font-size: 1em;
          }
          .form-subtitle {
            font-size: 0.8em;
          }
        }
      }
    }
    .sidebar {
      width: 30%;
      margin: 2em 0em;
      border-radius: 20px;
      background: $dark;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .resume-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 0;
        .resume-btn {
          z-index: 2;
          padding: 13px 50px 13px;
          border-radius: 10px;
          position: relative;
          border: 2px solid $light;
          cursor: pointer;
          background-color: transparent;
          box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
          &:after {
            content: "";
            border-radius: 10px;
            background-color: $light;
            width: 100%;
            z-index: -1;
            position: absolute;
            height: 105%;
            top: 5px;
            left: 5px;
            transition: 0.2s;
            border: 0px;
          }
          &:hover::after {
            top: -1px;
            left: 0px;
          }
          p {
            font-size: calc(min(1.7vw, 25px));
            color: $dark;
            font-family: "Fjalla One", sans-serif;
          }
        }

        .reference-paragraph {
          margin-top: 2em;
          font-size: 1.4vw;
          font-size: calc(min(1.4vw, 20px));
          margin: 2em 0em;
          text-align: center;
          color: white;
          width: 70%;
        }
      }
      .icon-container {
        display: flex;
        width: 50%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2em;
        transition: 0.3s ease;

        .media-icon {
          display: flex;
          gap: 1em;

          &:hover {
            transition: 0.3s ease;
            filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(118deg)
              brightness(103%) contrast(102%);
            color: white;
          }
        }
        .__media-icon {
          width: 5vw;
          max-width: 70px;
          height: auto;
          justify-self: center;
          filter: invert(94%) sepia(5%) saturate(2194%) hue-rotate(58deg)
            brightness(98%) contrast(91%);
        }
        .media-icon-text {
          align-self: center;
          font-size: 2vw;
          font-size: calc(min(2vw, 25px));
          font-family: "Fjalla One", sans-serif;
          color: $light;
        }
      }
      @media only screen and (min-width: 750px) {
        .mobile-resume-text {
          display: none;
        }
        .mobile-resume-btn {
          display: none;
        }
        .download-resume-btn {
          display: none;
        }
      }

      // MOBILE
      @media only screen and (max-width: 750px) {
        width: 100vw;
        flex-direction: row;
        flex-wrap: wrap;
        height: max-content;

        .resume-container {
          flex-direction: row;
          justify-content: space-around;
          margin: 1em 0em;
          .reference-paragraph {
            color: $gray;
            width: 33%;
            font-size: 2vw;
            padding: 1em 2.5em;
            margin: 0;
            border-radius: 10px;
            background-color: lightgray;
          }
          .resume-btn {
            display: none;
          }
          .mobile-resume {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .mobile-resume-text {
              display: visible;
              font-size: 1em;
              color: $light;
            }
            .mobile-resume-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              border: 0px;
              cursor: pointer;
              background-color: $light;
              border-radius: 10px;
              padding: 1em 3em;
              margin-top: 1em;
              transition: 0.3s ease;

              &:hover {
                box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.4);
                transition: 0.3s ease;
              }
              .__download {
                filter: invert(12%) sepia(32%) saturate(0%) hue-rotate(201deg)
                  brightness(93%) contrast(79%);
              }
            }
          }
        }
        .icon-container {
          width: 100vw;
          flex-direction: row;
          justify-content: center;
          background-color: $medium;
          display: flex;
          .__media-icon {
            cursor: pointer;
            margin: 1em;
            padding: 0;
            width: 8vw;
          }
          .media-icon-text {
            display: none;
          }
        }
      }
    }
  }
  .confirmation-popup {
    z-index: 99999999;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120%;
    background: rgba(255, 255, 255, 0.55);
    backdrop-filter: blur(4px);
    animation: fadein 0.2s ease;
    animation: fadeout 1.2s ease;

    .talk-soon {
      color: $text;
    }
    .__send-icon {
      -webkit-animation: slide 1s ease;
      animation: slide 1s ease;
      transition-timing-function: cubic-bezier(0.3, 0.7, 1, 0.3);
      transform: rotate(27deg);
    }
  }
}
