@import "../../assets/styles/global";
@import "../../assets/styles/page-layout";

.whats-crackin-container {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  justify-items: center;
  width: 100%;
  align-items: center;
  .mobile-title {
    text-align: center;
    .subtitle {
      padding: 0;
    }
    @media only screen and (min-width: 975px) {
      display: none;
    }
  }
  @media only screen and (max-width: 975px) {
    grid-template-columns: 1fr;
  }
  @media only screen and (min-width: 1500px) {
    width: 80%;
    justify-self: center;
    align-self: center;
  }
  .__graduate-owen {
    height: auto;
    justify-self: center;
    width: 80%;
    max-width: 700px;
  }
  .intro-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (max-width: 975px) {
      align-items: center;
      width: 80%;
      margin-top: 2em;
    }
    .title-subtitle {
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 975px) {
        display: none;
      }
    }
    .poppin-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 15%;
      @media only screen and (max-width: 975px) {
        margin: 0;
      }

      ul {
        padding: 0;
        margin-top: 1em;
        counter-reset: index;
      }
      li {
        padding: 0.3em;
        counter-increment: index;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;

        &:before {
          content: counters(index, ".", decimal-leading-zero);
          font-size: 1.5rem;
          text-align: right;
          font-weight: bold;
          min-width: 50px;
          padding-right: 12px;
          font-variant-numeric: tabular-nums;
          align-self: flex-start;
          background-image: linear-gradient(to bottom, $light, $medium);
          background-attachment: fixed;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      li + li {
        border-top: 1px solid rgba(72, 72, 72, 0.3);
      }
    }
    .resume-btn {
      align-self: center;
      position: relative;
      cursor: pointer;
      z-index: 1;
      width: max-content;
      margin-top: 2em;
      padding: 1em 1.5em;
      border: 2px solid $light;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
      transition: all 0.35s;
      border-radius: 10px;
      .resume-txt{
        @media only screen and (min-width: 950px) {
          font-size:1.2vw;
        }
        font-size:3vw;
      }
      &:after {
        position: absolute;
        content: "";
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 101%;
        margin-left: -0.5%;
        border-radius: 8px;
        height: 0px;
        background-color: $light;
        transition: all 0.35s;
      }

      &:hover:after {
        height: 100%;
        z-index: -1;
      }
      img {
        z-index: 2;
      }
      span {
        z-index: 2;
        display: flex;
        flex-direction: column;
        font-size: 1.5vw;
        color: $dark;
        font-family: "Fjalla One", sans-serif;
      }
    }

    .bold {
      font-weight: 800;
    }
  }
}
.__wave {
  margin-top: -22%;
  margin-left: -10%;
}
.flipped {
  margin-top: -1px;
  margin-left: 0;
  transform: rotateX(180deg);
}

.planting-container {
  background-color: $light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .__tree-number {
    width: 80%;
    margin-bottom: 5%;
  }
  .planting-summary {
    margin: 0 10%;
    gap: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 950px) {
      flex-direction: column;
      align-items: center;
    }
    .blog-post-link{
      color:$dark;
      text-decoration: underline;
    }

    .planting-text {
      width: 80%;
      padding: 1em 0em;
      @media only screen and (max-width: 950px) {
        order: 2;
      }
    }
    .crew-container {
      justify-self: center;
      align-self: center;
      width: 80%;
      padding: 8px;
      margin-bottom: 1em;
      background-image: linear-gradient(to top, #dd81fd, #4fc68a);
      box-shadow: 2px 2px 5px black;
      border-radius: 20px;

      @media only screen and (max-width: 950px) {
        order: 1;
      }
    }
    .__crew {
      width: 100%;
      padding-top: 10px;
      border-radius: 20px;
      height: auto;
    }
  }
}

.workplace-container {
  margin-inline: 20%;
  margin-top: -25%;
  max-width: 800px;
  justify-self: center;
  align-self: center;
  .and-text-pair {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10% 0%;
    width: 100%;
    .looking-for-text {
      font-size: calc(min(5vw, 80px));
      align-self: flex-start;
      color: rgba(220, 220, 220, 0.6);
      -webkit-text-stroke: 1px black;
    }
    .and-text {
      font-size: calc(min(4vw, 70px));
      align-self: center;
      color: $gray;
    }
    .can-offer-text {
      font-size: calc(min(4vw, 70px));
      align-self: flex-end;
      color: $medium;
      text-align: end;
    }
  }
  .gradient-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 7.5%;

    li {
      background: white;
      border-radius: 0 0.5rem 0.5rem 0.5rem;
      counter-increment: gradient-counter;
      margin-bottom: 10%;
      padding: 1.5em 1em;
      position: relative;
      font-size: calc(min(1.3vw, 25px));
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);

      &::before,
      &::after {
        font-size: calc(min(1.5vw, 20px));
        font-weight: 700;
        content: "";
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(135deg, white 0%, $light 100%);
        border-radius: 10px;
        left: -15%;
        top: 0;
        position: absolute;
        top: -1.6rem;
        @media only screen and (max-width: 900px) {
          top: -1.3rem;
        }
        @media only screen and (max-width: 450px) {
          top: -0.5rem;
        }
      }
      &::before {
        // max-width: 100%;
        
        content: attr(data-looking-for);
        white-space:nowrap;
        color: $dark;
        padding: 0.125em 1em;
        z-index: 1;
      }
    }
  }
}
