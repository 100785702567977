@import "../../assets/styles/global";
@import "../../assets/styles/page-layout";
@import "../../assets//styles/blobz/blobz.min.css";
a {
  color: $dark;
}
.cbc-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: max-content;
  &:hover {
    .__cbc {
      width: 3em;
      transition: 0.3s ease;
    }
  }
  .__cbc {
    width: 2.5em;
    margin-right: 2em;
    transition: 0.3s ease;
  }

  &:hover {
    width: 120%;
    height: 120%;
  }
  &:hover:after {
    width: 120%;
    height: 120%;
  }
}

.master-container {
  overflow-x: hidden;
}
.no-bot-margin {
  margin-bottom: 0;
}
.main-title-container {
  width: 100%;
  box-sizing: border-box;
  padding-inline: 15%;
  margin-bottom: 2em;
  .title,
  .subtitle {
    padding-bottom: 0.5em;
  }
}
.in-text-link {
  text-decoration: underline;
  font-weight: 700;
  border-bottom: 2px solid $medium;
}
.summary-container {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 70%;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (min-width: 1200px) {
    margin-top: -2em;
  }
  @media only screen and (min-width: 1400px) {
    margin-top: -6em;
  }
  @media only screen and (min-width: 1600px) {
    margin-top: -10em;
  }
  .__team-members {
    position: absolute;
    width: 40%;
    max-width: 700px;
    margin-bottom: 12%;
    margin-left: 50%;
    height: auto;
    @media only screen and (max-width: 600px) {
      width: 60%;
      margin-left: 0;
      margin-top: 4em;
    }
    // @media only screen and (min-width: 1200px) {
    //     width: 180%;
    //     max-width: 35em;
    // }
  }
  .__music-tab {
    z-index: 2;
    margin-top: 20%;
    width: 105%;
    height: auto;
  }
}

.summary-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 1.6vw;
  margin-bottom: 20%;
  margin-right: 35%;
  text-align: center;
  width: 35%;

  .teammates-container {
    z-index: 20;
    display: flex;
    justify-self: center;
    align-self: center;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    padding: 1em;
    border-radius: 20px;
    box-shadow: 1px 1px 5px $gray;
    background-color: $medium;
    filter: drop-shadow(3px 5px 2px rgb(225, 255, 255 / 0.2));
    margin-top: 2em;
    @media only screen and (max-width: 600px) {
      display: none;
    }

    .big-screen-text {
      @media only screen and (min-width: 1200px) {
        width: 20%;
        background-color: blue;
      }
    }
    .names {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: nowrap;
      // gap: 0.2em;
    }
    a {
      padding: 0.2em 0em;
      color: lightgreen;
      font-size: 0.6em;
      text-align: end;

      &:hover {
        color: $light;
      }
    }
    h3 {
      color: $accent;
      text-align: left;
      font-size: 0.9em;
    }
  }
  @media only screen and (max-width: 600px) {
    position: relative;
    width: 60%;
    height: max-content;
    margin-right: 0;
    font-size: 2.2vw;
    // top: 0;
  }
}
.blob {
  position: absolute;
  max-width: 900px;
  z-index: -1;
  width: 60%;
  margin-left: 55%;

  @media only screen and (max-width: 600px) {
    margin-left: 0%;
    margin-bottom: 5%;
  }
  .tk-blob {
    --time: 120s;
    --amount: 10;
    --fill: #74c69d;
    width: 100%;
    height: auto;
  }
}
.step-header {
  color: $dark;
  margin-bottom: 1em;
}

.musical-concept-list {
  margin: 1em 0em;
}
ul li {
  list-style-position: inside;
  display: list-item;
  list-style-type: circle;
}

.pattern-columns {
  display: flex;
  flex-direction: row;
  width: 140%;
  margin-top: 1em;
  .col {
    display: flex;
    align-items: center;
    width: 50%;
    flex-direction: column;
    margin: 2em;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
}
.columned-container {
  li {
    padding: 20px;
    display: list-item;
    list-style: none;
    outline: 2px solid $gray;
    margin: 0.5em 0em;
    box-shadow: 1px 1px 10px $gray;
    border-radius: 20px;
    img {
      width: 100%;
      max-width: 20em;
      height: auto;
      padding-top: 1em;
    }
  }
  .step-header,
  p {
    align-self: flex-start;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
}
.pattern-summary {
  margin-bottom: 2em;
}

.definition-header {
  color: $medium;
  width: 100%;
}

li.tonal-table {
  margin: 0.5em 0em;
  font-size: 12px;
  border-radius: 20px;

  table {
    border-collapse: collapse;
    border-style: hidden;
  }
  thead {
    color: $medium;
    border: 3px solid $gray;
  }
  th {
    vertical-align: middle;
    text-align: center;
    padding: 0.5em;
  }
  tr {
    border-bottom: 1px solid $gray;
  }
  td {
    vertical-align: middle;
    text-align: center;
    padding: 0.1em 0.5em;
  }
}
.pattern-conclusion {
  width: 60%;
  text-align: center;
}

.example-composition {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 4em;
  .example-composition-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .__play-icon {
    margin-right: 2em;
    width: 5%;
  }
}
.poster-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 2em;
  .__poster {
    width: 100%;
    border-radius: 20px;
  }
}
