// @import "../../assets/styles/global";
// @import "../../assets/styles/page-layout";

// .loader {
//   background: radial-gradient(ellipse at top, $medium, transparent),
//     radial-gradient(ellipse at bottom, $light, transparent);
//   position: absolute;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100vw;
//   height: 100vh;
// }
// .loading-txt {
//   color: $gray;
//   position: absolute;
//   top: calc(40% + 100px);
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   align-items: center;
// }

// .fade {
//   animation: fadeIn 1s ease;
// }
// .box {
//   width: 50px;
//   height: 50px;
//   top: calc(40% - 20px);
//   background: $light;
//   animation: animate 0.5s linear infinite;
//   position: absolute;
//   border-radius: 3px;
// }
// .shadow {
//   width: 50px;
//   height: 5px;
//   background: #000;
//   opacity: 0.1;
//   position: absolute;
//   top: calc(40% + 49px);
//   border-radius: 50%;
//   animation: shadow 0.5s linear infinite;
// }

// .ellipses::before {
//   font-size: 3em;
//   color: $dark;
//   content: " ";
//   animation: ellipses alternate infinite;
//   animation-duration: 1.5s;
//   text-align: center;
// }

// // Transform taken from Free Frontend: https://codepen.io/_fbrz/pen/dyebWj?editors=1100
// @keyframes animate {
//   17% {
//     border-bottom-right-radius: 3px;
//   }
//   25% {
//     transform: translateY(9px) rotate(22.5deg);
//   }
//   50% {
//     transform: translateY(18px) scale(1, 0.9) rotate(45deg);
//     border-bottom-right-radius: 40px;
//   }
//   75% {
//     transform: translateY(9px) rotate(67.5deg);
//   }
//   100% {
//     transform: translateY(0) rotate(90deg);
//   }
// }
// @keyframes shadow {
//   50% {
//     transform: scale(1.2, 1);
//   }
// }
// @keyframes loader {
//   0% {
//     left: -100px;
//   }
//   100% {
//     left: 110%;
//   }
// }
// @keyframes ellipses {
//   25% {
//     content: ".";
//   }
//   50% {
//     content: "..";
//   }
//   100% {
//     content: "...";
//   }
// }

// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @keyframes fadeOut {
//   0% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(235, 235, 235, 1); // Semi-transparent white background
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  animation: fadeOutBlur 2s forwards; // You can adjust the duration
}

.loader-content {
  font-size: 24px; // Adjust as needed
  color: #333; // Adjust as needed
}

@keyframes fadeOutBlur {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
