@import "../assets/styles/global";

.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $light;

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .media-icon {
      &:hover {
        .__media-icon {
          filter: invert(43%) sepia(18%) saturate(989%) hue-rotate(95deg)
            brightness(92%) contrast(86%);
        }
      }
    }
    .__media-icon {
      padding: 10%;
      margin: 0em 1em;
      width: 5vw;
      height: auto;
      justify-self: center;
      @media only screen and (max-width: 600px) {
        width: 8vw;
        margin: 0 0.5em;
      }
    }
  }
  .back-to-top {
    cursor: pointer;
    margin: 1em 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      .__media-icon {
        filter: invert(43%) sepia(18%) saturate(989%) hue-rotate(95deg)
          brightness(92%) contrast(86%);
      }
    }
    @media only screen and (max-width: 600px) {
      margin: 1em 1.5em;
    }
    .back-to-top-text {
      text-align: center;
    }
  }
}
