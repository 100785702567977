@import "../../../assets//styles/_colors";

.blog-post {
  @media only screen and (max-width: 600px) {
    margin-inline: 10%;
  }

  margin-inline: 20%;
  display: flex;
  flex-direction: column;
  .back-to-blog {
    transition: ease 1s;
    &:hover {
      color: $medium;
      transform: translateX(10px);
      transition: ease 1s;
    }
  }

  .__main {
    justify-self: center;
    align-self: center;
    width: 80%;
    border-radius: 20px;
    margin-bottom: 1em;
  }
  // image from not-so-fun-facts
  .img-container {
    display: flex;
    margin: 2em 0em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .subtitle {
    color: $medium;
  }
  .date {
    margin: 1em 0em;
    color: $gray;
  }
  .bold {
    font-weight: bold;
  }
  .italic {
    font-style: italic;
  }
  .light {
    color: #f7f7f7;
  }

  .sub-list {
    margin: 0.5em 2em;
  }
  .accreditation {
    font-size: 10px;
    color: #333;
  }
  section {
    margin-bottom: 2em;
  }

  p {
    margin: 0.5em 0em;
  }
  ol,
  ul {
    padding: 0;
  }
  li {
    list-style-position: outside;
    margin: 0.5em 1em;
  }

  table {
    font-size: 1.1vw;
    @media only screen and (max-width: 750px) {
      font-size: 1.8vw;
    }
    td,
    tr,
    th {
      padding: 0.5em;
      border: 1px solid $gray;
    }
    th {
      font-weight: bold;
    }
    border: 1px solid $gray;
  }
}

.example-bg {
  border-radius: 20px;
  padding: 10px;
  background-color: $light;
  color: #333;
}

.fun-fact {
  border-radius: 20px;
  padding: 10px;
  background-color: $gray;
  color: #f7f7f7;
}

.small-txt-box {
  width: fit-content;
}

.research-links {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 2em;
}

.link {
  color: $medium; // Default blue color for links
  text-decoration: none; // Remove underline
  transition: color 0.3s ease; // Smooth color transition on hover

  // Hover styling
  &:hover {
    color: $dark; // A darker shade of blue on hover
    text-decoration: underline; // Underline on hover
  }
}
// NOT SO FUN FACTS POST
.img-title-container {
  background-image: url("../../../assets/images/blog/not-so-fun_GIF.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: width height;
  width:width;
  height:20vw;
  padding: 4em;
  min-height:200px;
  padding-top: 2em;
  border-radius: 20px;
  text-align: end;
  @media only screen and (max-width: 600px) {
    padding:1em;
  }
  .img-container-title{
    @media only screen and (max-width: 700px) {
      font-size:24px;
    }
  }
}
.gif-hero {
  width: 100%;
  height: 50vw;
}
.fact-list {
  p {
    margin: 0.5em 0;
  }
  gap: 1em;
  display: flex;
  flex-direction: column;
  list-style-type: decimal;
  .fact-list-item {
    padding: 1em;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .note{
      background-color: #f7f7f796;
      color:$gray;
      border-radius:8px;
      padding:0.5em;
    }
    .list-number {
      flex: 1;
      text-align: center;
      @media only screen and (max-width: 700px) {
        font-size:24px;
        text-align: start;
      }
    }
    .list-content {
      flex: 6;
    }
  }
  .blue {
    background-color: #335867;
    color: #f7f7f7;
    a {
      color: lightgray;
      text-decoration: underline;
      &:hover{
        color:#ecbda8;
      }
    }
  }
  .orange {
    background-color: #ecbda8;
    a {
      color: $gray;
      text-decoration: underline;
      &:hover{
        color:#335867;
      }
    }
  }
}


// END OF NOT SO FUN FACTS POST
